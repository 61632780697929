<template>
    <div>
        <b-tabs>
                <b-card-actions
                title="Filters"
                action-collapse
                >
                    <b-card-text>
                        <b-row>
                            <b-col md="4">
                                <b-form-group
                                    label="Role"
                                    label-for="lead-source-filter"
                                >
                                    <custom-autopopulate
                                        id="lead-source-filter"
                                        module="roles"
                                        label="name"
                                        @changed="rolesChanged"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    label="Branch"
                                    label-for="lead-source-filter"
                                >
                                    <custom-autopopulate
                                        id="lead-source-filter"
                                        module="branches"
                                        label="name"
                                        @changed="branchesChanged"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card-actions>
                <List
                    model="users"
                    :can-delete="true"
                    :can-edit="true"
                    :can-view="false"
                    default-sort="id"
                    default-sort-order="desc"
                    :where="memberCondition"
                />
        </b-tabs>
    </div>
</template>

<script>
import {
    BTabs,
    BTab,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import List from '../common/list';
import CustomAutopopulate from '../common/components/CustomAutopopulate';
export default {
    components: {
        BTabs,
        BCardText,
        List,
        BCardActions,
        BRow,
        BCol,
        BFormGroup,
        CustomAutopopulate,
    },
    data() {
        return {
            totalContacts: 'loading...',
            totalMembers: 'loading...',
            totalLeads: 'loading...',
            totalSubscribers: 'loading...',
            createDateFilter: '',
            leadStatusFilter: '',
            leadSourceFilter: '',
            statusFilter: '',
            memberCondition: {
                where_fields: [],
                where_values: [],
            },
            showOnlyCritical: '0',
            showAtRisk: '0',
        };
    },
    // async created() {
    //     this.fetchData();
    // },
    methods: {
        rolesChanged(val) {
            if (val > 0) {
                this.memberCondition.where_fields = [
                    ...new Set([
                        ...this.memberCondition.where_fields,
                        'role_id',
                    ]),
                ];
                this.memberCondition.where_values[
                    this.memberCondition.where_fields.indexOf('role_id')
                ] = val;
            } else {
                const ind = this.memberCondition.where_fields.indexOf(
                    'role_id',
                );
                this.memberCondition.where_fields.splice(ind, 1);
                this.memberCondition.where_values.splice(ind, 1);
            }
        },
        branchesChanged(val) {
            if (val > 0) {
                this.memberCondition.where_fields = [
                    ...new Set([
                        ...this.memberCondition.where_fields,
                        'branch_id',
                    ]),
                ];
                this.memberCondition.where_values[
                    this.memberCondition.where_fields.indexOf('branch_id')
                ] = val;
            } else {
                const ind = this.memberCondition.where_fields.indexOf(
                    'branch_id',
                );
                this.memberCondition.where_fields.splice(ind, 1);
                this.memberCondition.where_values.splice(ind, 1);
            }
        },
        refreshList() {
            console.log('called');
        },
    },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.custom-control.custom-checkbox {
    top: 40%;
}
.checkbox-container {
    justify-content: space-around;
    padding: 2rem 0;
}
</style>
